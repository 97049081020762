:root {
    scroll-behavior: smooth;
}
body {
    font-family: 'Open Sans', sans-serif;
    color: #444444;
}
a {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    text-decoration: none !important;
}
a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Nunito', sans-serif;
}
/*--------------------------------------------------------------
 # Sections
 --------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}
.section-header {
    text-align: center;
    padding-bottom: 40px;
}
.section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    text-transform: uppercase;
}
.section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 25px;
    line-height: 42px;
    font-weight: 700;
    color: #026134;
}
@media (max-width: 768px) {
    .section-header p {
        font-size: 28px;
        line-height: 32px;
    }
}
/*--------------------------------------------------------------
 # Breadcrumbs
 --------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: #026134;
    min-height: 40px;
    margin-top: 82px;
    color: #fff;
}
.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 500;
}
.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
}
.breadcrumbs ol a {
    color: #fff;
    transition: 0.3s;
}
.breadcrumbs ol a:hover {
    text-decoration: underline;
}
.breadcrumbs ol li + li {
    padding-left: 10px;
}
.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #8894f6;
    content: '/';
}
@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 57px;
    }
}
/*--------------------------------------------------------------
 # Back to top button
 --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}
.back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
}
.back-to-top:hover {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
}
.back-to-top.active {
    visibility: visible;
    opacity: 1;
}
/*--------------------------------------------------------------
 # Disable aos animation delay on mobile devices
 --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}
/*--------------------------------------------------------------
 # Header
 --------------------------------------------------------------*/
.header {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
}
.header.header-scrolled {
    background: #fff;
    padding: 15px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}
.header.header-scrolled > div > .navbar > ul > li > a {
    color: #026134;
}
.header.header-scrolled > div > .navbar > .mobile-nav-toggle {
    color: #026134;
}
.white-background {
    color: #026134 !important;
}
.header .logo {
    line-height: 0;
}
.header .logo img {
    max-height: 60px;
    margin-right: 6px;
}
.header .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #026134;
    font-family: 'Nunito', sans-serif;
    margin-top: 3px;
}
/*--------------------------------------------------------------
 # Navigation Menu
 --------------------------------------------------------------*/
/**
 * Desktop Navigation 
 */
.navbar {
    padding: 0;
}
.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}
.navbar li {
    position: relative;
}
.navbar a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
}
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #016938;
    white-space: nowrap;
    transition: 0.3s;
}
.nav-link:focus,
.nav-link:hover {
    color: #1dac6c !important;
}
a:hover {
    color: #1dac6c !important;
}
.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}
.navbar a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar .active {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar .active:focus {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar li:hover > a {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar .getstarted {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    padding: 8px 20px 8px 14px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff !important;
}
.navbar .getstarted:hover {
    color: #d4d3d3 !important;
}
.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
}
.navbar .dropdown ul li {
    min-width: 200px;
}
.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
    color: #026134 !important;
}
.navbar .dropdown ul a i {
    font-size: 12px;
}
.navbar .dropdown ul a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}
@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}
/**
 * Mobile Navigation 
 */
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
    color: #fff !important;
}
@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }
    .navbar ul {
        display: none;
    }
    .login-icon {
        display: none;
    }
}
.navbar-mobile {
    position: fixed !important;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgb(1 105 56);
    transition: 0.3s;
}
.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}
.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}
.navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #535a57;
}
.navbar-mobile a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar-mobile .getstarted {
    margin: 15px;
}
.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
    min-width: 200px;
}
.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}
/*--------------------------------------------------------------
 # Hero Section
 --------------------------------------------------------------*/
/* main */
.hero {
    width: 100%;
    height: 100vh;
    background: url(../img/main-img.png) top center no-repeat;
    background-size: cover;
}
.hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
}
.hero h2 {
    color: #fff;
    margin: 15px 0 0 0;
    font-size: 26px;
}
.hero .btn-get-started {
    margin-top: 30px;
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}
.hero .btn-get-started span {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}
.hero .btn-get-started i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
}
.hero .btn-get-started:hover i {
    transform: translateX(5px);
}
.hero .hero-img {
    text-align: right;
}
@media (min-width: 1024px) {
    .hero {
        background-attachment: fixed;
    }
}
@media (max-width: 991px) {
    .hero {
        height: auto;
        padding: 120px 0 60px 0;
    }
    .hero .hero-img {
        text-align: center;
        margin-top: 80px;
    }
    .hero .hero-img img {
        width: 80%;
    }
}
@media (max-width: 768px) {
    .hero {
        text-align: center;
    }
    .hero h1 {
        font-size: 32px;
    }
    .hero h2 {
        font-size: 24px;
    }
    .hero .hero-img img {
        width: 100%;
    }
}

/* housing */
.housing-hero {
    width: 100%;
    height: auto;
    padding: 0px !important;
    /* background: url(../img/housing-finance.png) top center no-repeat; */
    background-size: cover;
}
.housing-hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
}
.housing-hero h2 {
    color: #fff;
    margin: 15px 0 0 0;
    font-size: 26px;
}
.housing-hero .btn-get-started {
    margin-top: 30px;
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}
.housing-hero .btn-get-started span {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}
.housing-hero .btn-get-started i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
}
.housing-hero .btn-get-started:hover i {
    transform: translateX(5px);
}
.housing-hero .housing-hero-img {
    text-align: right;
}
@media (max-width: 991px) {
    .housing-hero {
        height: auto;
    }
    /* .housing-hero .housing-hero-img {
		text-align: center;
		margin-top: 80px;
	}
	.housing-hero .housing-hero-img img {
		width: 80%;
	} */
}
@media (max-width: 768px) {
    .housing-hero {
        height: auto;
    }
}
/*--------------------------------------------------------------
 # Index Page
 --------------------------------------------------------------*/
/*--------------------------------------------------------------
 # About
 --------------------------------------------------------------*/
.about-us {
    padding: 130px 0;
}
.about-us .content {
    padding: 40px;
    background-color: #fff;
}
.why-choose-us {
    padding: 60px 0;
}
.img-fluid-banner {
    width: 100%;
    max-width: 800px;
}
@media (max-width: 487px) {
    .img-fluid-banner {
        width: 150%;
    }
}
.text-underline {
    text-decoration-line: underline;
    text-decoration-color: #016938;
    text-decoration-thickness: 0.2em;
}
.why-choose-us .content {
    padding: 40px;
    background-color: #fff;
}
.image-text {
    position: absolute;
    /* top: 15px; */
    left: 20px;
    color: white;
}
.calculate-image-text {
    position: absolute;
    left: 20px;
    color: white;
}
.about {
    padding: 50px 0 230px 0;
}
.about .content {
    padding: 40px;
    box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -webkit-box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -moz-box-shadow: 6px 10px 49px 12px rgba(0, 0, 0, 0.53);
    border-radius: 30px;
    background-color: #fff;
}
.disclaimer-box {
    position: absolute;
    top: 230px;
    right: 232px;
}
.about h3 {
    font-size: 14px;
    font-weight: 700;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    text-transform: uppercase;
}
.about h2 {
    font-size: 24px;
    font-weight: 700;
    color: #026134;
}
.about p {
    margin: 15px 0 18px 0;
    line-height: 24px;
    font-size: 18px;
    font-weight: 600;
    color: #737373;
}
.about .btn-read-more {
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}
.about .btn-read-more span {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}
.about .btn-read-more i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
}
.about .btn-read-more:hover i {
    transform: translateX(5px);
}
/*--------------------------------------------------------------
 # Values
 --------------------------------------------------------------*/
.values .box {
    padding: 30px;
    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
    text-align: center;
    transition: 0.3s;
    height: 100%;
}
.values .box img {
    padding: 30px 50px;
    transition: 0.5s;
    transform: scale(1.1);
    height: 150px;
}
.values .box h3 {
    font-size: 24px;
    color: #026134;
    font-weight: 700;
    margin-bottom: 18px;
}
.values .box:hover {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}
.values .box:hover img {
    transform: scale(1);
}
/*--------------------------------------------------------------
 # Counts
 --------------------------------------------------------------*/
.counts {
    padding: 70px 0 60px;
}
.counts .count-box {
    display: flex;
    align-items: center;
    padding: 30px;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}
.counts .count-box i {
    font-size: 42px;
    line-height: 0;
    margin-right: 20px;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #0b198f;
}
.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
}
/*--------------------------------------------------------------
 # Features
 --------------------------------------------------------------*/
.features .feature-box {
    padding: 24px 20px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
    height: 100%;
}
.features .feature-box h3 {
    font-size: 18px;
    color: #026134;
    font-weight: 700;
    margin: 0;
}
.features .feature-box i {
    line-height: 0;
    background: #9cffd312;
    padding: 4px;
    margin-right: 10px;
    font-size: 24px;
    border-radius: 3px;
    transition: 0.3s;
}
.features .feature-box:hover i {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
}
.features .feture-tabs {
    margin-top: 120px;
}
.features .feture-tabs h3 {
    color: #026134;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
}
.features .feture-tabs .nav-pills {
    border-bottom: 1px solid #eee;
}
.features .feture-tabs .nav-link {
    background: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #026134;
    padding: 12px 0;
    margin-right: 25px;
    margin-bottom: -2px;
    border-radius: 0;
}
.features .feture-tabs .nav-link.active {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    border-bottom: 3px solid linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.features .feture-tabs .tab-content h4 {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
    color: #026134;
}
.features .feture-tabs .tab-content i {
    font-size: 24px;
    line-height: 0;
    margin-right: 8px;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.features .feature-icons {
    margin-top: 120px;
}
.features .feature-icons h3 {
    color: #026134;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}
.features .feature-icons .content .icon-box {
    display: flex;
}
.features .feature-icons .content .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: #026134;
}
.features .feature-icons .content .icon-box i {
    font-size: 44px;
    line-height: 44px;
    color: #0245bc;
    margin-right: 15px;
}
.features .feature-icons .content .icon-box p {
    font-size: 15px;
    color: #848484;
}
@media (max-width: 768px) {
    .features .feture-tabs h3 {
        font-size: 28px;
    }
}
@media (max-width: 768px) {
    .features .feature-icons h3 {
        font-size: 28px;
    }
}
/*--------------------------------------------------------------
 # Services
 --------------------------------------------------------------*/
.services .service-box {
    box-shadow: 0px 0 30px #03700180;
    height: 100%;
    text-align: center;
    transition: 0.3s;
    border-radius: 10px;
}
.services .service-box .service-box-green-header {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
    border-radius: 10px 10px 0 0;
    margin: 0px -24px;
    height: 40px;
    padding-top: 4px;
    font-size: 19px;
}
.services .service-box .service-box-gray-header {
    background: linear-gradient(90deg, rgba(55, 57, 55, 1) 35%, rgba(133, 140, 136, 1) 100%);
    color: #fff;
    border-radius: 10px 10px 0 0;
    margin: 0px -24px;
    height: 40px;
    padding-top: 4px;
    font-size: 19px;
}
.amount-color {
    color: #615f5f;
}
.text-green {
    color: #016939;
}
.text-orange {
    color: #e78d05;
}
.text-19 {
    font-size: 19px;
}
.services .service-box .icon {
    font-size: 36px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
}
.services .service-box h3 {
    color: #444444;
    font-weight: 700;
}
.services .service-box .read-more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 20px;
}
.services .service-box .read-more i {
    line-height: 0;
    margin-left: 5px;
    font-size: 18px;
}
.services .service-box.blue {
    border-bottom: 3px solid #2db6fa;
}
.services .service-box.blue .icon {
    color: #2db6fa;
    background: #dbf3fe;
}
.services .service-box.blue .read-more {
    color: #2db6fa;
}
.services .service-box.blue:hover {
    background: #2db6fa;
}
.services .service-box.orange {
    border-bottom: 3px solid #f68c09;
}
.services .service-box.orange .icon {
    color: #f68c09;
    background: #fde3c4;
}
.services .service-box.orange .read-more {
    color: #f68c09;
}
.services .service-box.orange:hover {
    background: #f68c09;
}
.services .service-box.green {
    border-bottom: 3px solid #08da4e;
}
.services .service-box.green .icon {
    color: #08da4e;
    background: #cffddf;
}
.services .service-box.green .read-more {
    color: #08da4e;
}
.services .service-box.green:hover {
    background: #08da4e;
}
.services .service-box.red {
    border-bottom: 3px solid #e9222c;
}
.services .service-box.red .icon {
    color: #e9222c;
    background: #fef7f8;
}
.services .service-box.red .read-more {
    color: #e9222c;
}
.services .service-box.red:hover {
    background: #e9222c;
}
.services .service-box.purple {
    border-bottom: 3px solid #b50edf;
}
.services .service-box.purple .icon {
    color: #b50edf;
    background: #f8e4fd;
}
.services .service-box.purple .read-more {
    color: #b50edf;
}
.services .service-box.purple:hover {
    background: #b50edf;
}
.services .service-box.pink {
    border-bottom: 3px solid #f51f9c;
}
.services .service-box.pink .icon {
    color: #f51f9c;
    background: #feecf7;
}
.services .service-box.pink .read-more {
    color: #f51f9c;
}
.services .service-box.pink:hover {
    background: #f51f9c;
}
/*--------------------------------------------------------------
 # Pricing
 --------------------------------------------------------------*/
.pricing .box {
    padding: 40px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
}
.pricing .box:hover {
    transform: scale(1.1);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}
.pricing h3 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
}
.pricing .price {
    font-size: 36px;
    color: #444444;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.pricing .price sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}
.pricing .price span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}
.pricing img {
    padding: 30px 40px;
}
.pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 25px;
}
.pricing ul li {
    padding-bottom: 10px;
}
.pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
}
.pricing .btn-buy {
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border: 1px solid linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.pricing .btn-buy:hover {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
}
.pricing .featured {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
}
/*--------------------------------------------------------------
 # F.A.Q
 --------------------------------------------------------------*/
.faq .accordion-collapse {
    border: 0;
}
.faq .accordion-button {
    padding: 15px 15px 20px 0;
    font-weight: 600;
    border: 0;
    font-size: 25px;
    color: #016938;
    text-align: left;
}
.faq .accordion-button:focus {
    box-shadow: none;
}
.faq .accordion-button:not(.collapsed) {
    background: none;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    border-bottom: 0;
}
.faq .accordion-body {
    padding: 0 0 25px 0;
    border: 0;
}
/*--------------------------------------------------------------
 # Portfolio
 --------------------------------------------------------------*/
.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    background: white;
    border-radius: 50px;
    padding: 2px 15px;
}
.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    color: #444444;
    margin: 0 4px 8px 4px;
    transition: 0.3s;
    border-radius: 50px;
    border: 1px solid #fff;
}
.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    border-color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}
.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(255, 255, 255, 0.75);
}
.portfolio .portfolio-wrap::before {
    content: '';
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}
.portfolio .portfolio-wrap img {
    transition: 1s;
}
.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid rgba(1, 41, 112, 0.2);
    border-left: 3px solid rgba(1, 41, 112, 0.2);
    transition: all 0.5s ease 0s;
    z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid rgba(1, 41, 112, 0.2);
    border-right: 3px solid rgba(1, 41, 112, 0.2);
    transition: all 0.5s ease 0s;
    z-index: 9994;
}
.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #026134;
    font-weight: 700;
}
.portfolio .portfolio-wrap .portfolio-info p {
    color: #026134;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}
.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}
.portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    margin: 10px 2px;
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
}
.portfolio .portfolio-wrap .portfolio-links a i {
    font-size: 24px;
    line-height: 0;
}
.portfolio .portfolio-wrap .portfolio-links a:hover {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.portfolio .portfolio-wrap:hover img {
    transform: scale(1.1);
}
.portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}
.portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
}
.portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
}
/*--------------------------------------------------------------
 # Portfolio Details
 --------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
    width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}
.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
}
.portfolio-details .portfolio-description {
    padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
    padding: 0;
}
/*--------------------------------------------------------------
 # Testimonials
 --------------------------------------------------------------*/
.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px;
    margin: 40px 30px;
    box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: 0.3s;
}
.testimonials .testimonial-item .stars {
    margin-bottom: 15px;
}
.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}
.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border: 4px solid #fff;
    margin: 0 auto;
}
.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}
.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}
.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0px !important;
}
/* .testimonials .swiper-pagination .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background-color: #fff;
	opacity: 1;
	border: 1px solid linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
	background-color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
} */
.testimonials .swiper-slide {
    opacity: 1;
}
@media (max-width: 1199px) {
    .testimonials .swiper-slide-active {
        opacity: 1;
    }
    .testimonials .swiper-pagination {
        margin-top: 0;
    }
}
@media (min-width: 1200px) {
    .testimonials .swiper-slide-next {
        opacity: 1;
        transform: scale(1.12);
    }
}
/*--------------------------------------------------------------
 # Team
 --------------------------------------------------------------*/
.team {
    background: #fff;
    padding: 60px 0;
}
.team .member {
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
}
.team .member .member-img {
    position: relative;
    overflow: hidden;
}
.team .member .member-img:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: url(../img/team-shape.svg) no-repeat center bottom;
    background-size: contain;
    z-index: 1;
}
.team .member .social {
    position: absolute;
    right: -100%;
    top: 30px;
    opacity: 0;
    border-radius: 4px;
    transition: 0.5s;
    background: rgba(255, 255, 255, 0.3);
    z-index: 2;
}
.team .member .social a {
    transition: color 0.3s;
    color: rgba(1, 41, 112, 0.5);
    margin: 15px 12px;
    display: block;
    line-height: 0;
    text-align: center;
}
.team .member .social a:hover {
    color: rgba(1, 41, 112, 0.8);
}
.team .member .social i {
    font-size: 18px;
}
.team .member .member-info {
    padding: 10px 15px 20px 15px;
}
.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #026134;
}
.team .member .member-info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
}
.team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: #5e5e5e;
}
.team .member:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}
.team .member:hover .social {
    right: 8px;
    opacity: 1;
}
/*--------------------------------------------------------------
 # Clients
 --------------------------------------------------------------*/
.clients .clients-slider .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
}
.clients .clients-slider .swiper-slide img:hover {
    opacity: 1;
}
.clients .clients-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
/*--------------------------------------------------------------
 # Recent Blog Posts
 --------------------------------------------------------------*/
.recent-blog-posts .post-box {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
    height: 100%;
    overflow: hidden;
    padding: 30px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
}
.recent-blog-posts .post-box .post-img {
    overflow: hidden;
    margin: -30px -30px 15px -30px;
    position: relative;
}
.recent-blog-posts .post-box .post-img img {
    transition: 0.5s;
}
.recent-blog-posts .post-box .post-date {
    font-size: 16px;
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
    display: block;
    margin-bottom: 10px;
}
.recent-blog-posts .post-box .post-title {
    font-size: 24px;
    color: #026134;
    font-weight: 700;
    margin-bottom: 18px;
    position: relative;
    transition: 0.3s;
}
.recent-blog-posts .post-box .readmore {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 1;
    transition: 0.3s;
}
.recent-blog-posts .post-box .readmore i {
    line-height: 0;
    margin-left: 4px;
    font-size: 18px;
}
.recent-blog-posts .post-box:hover .post-title {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.recent-blog-posts .post-box:hover .post-img img {
    transform: rotate(6deg) scale(1.2);
}
/*--------------------------------------------------------------
 # Contact
 --------------------------------------------------------------*/
.contact .info-box {
    color: #444444;
    background: #9cffd312;
    padding: 30px;
}
.contact .info-box i {
    font-size: 25px;
    line-height: 0;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.contact .info-box h3 {
    font-size: 20px;
    color: #026134;
    font-weight: 700;
    margin: 20px 0 10px 0;
}
.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}
.contact .php-email-form {
    box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -webkit-box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -moz-box-shadow: 6px 10px 49px 12px rgba(0, 0, 0, 0.53);
    border-radius: 30px;
    background-color: #fff;
    padding: 30px;
    height: 100%;
}
.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}
.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}
.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}
.contact .php-email-form .loading:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input,
.contact .php-email-form textarea,
.contact .php-email-form select {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 0;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.contact .php-email-form input {
    padding: 10px 15px;
}
.contact .php-email-form textarea {
    padding: 12px 15px;
}
.contact .php-email-form button[type='submit'] {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}
.contact .php-email-form button[type='submit']:hover {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/*--------------------------------------------------------------
 # Blog
 --------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
}
.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}
.blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}
.blog .entry .entry-title a {
    color: #026134;
    transition: 0.3s;
}
.blog .entry .entry-title a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #4084fd;
}
.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}
.blog .entry .entry-meta ul li + li {
    padding-left: 20px;
}
.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}
.blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}
.blog .entry .entry-content p {
    line-height: 24px;
}
.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}
.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}
.blog .entry .entry-content .read-more a:hover {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}
.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}
.blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}
.blog .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #026134;
    margin-top: 20px;
    margin-bottom: 20px;
}
.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}
.blog .entry .entry-footer i {
    color: #0d64fd;
    display: inline;
}
.blog .entry .entry-footer a {
    color: #016938;
    transition: 0.3s;
}
.blog .entry .entry-footer a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}
.blog .entry .entry-footer .cats li {
    display: inline-block;
}
.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}
.blog .entry .entry-footer .tags li {
    display: inline-block;
}
.blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ',';
}
.blog .entry .entry-footer .share {
    font-size: 16px;
}
.blog .entry .entry-footer .share i {
    padding-left: 5px;
}
.blog .entry-single {
    margin-bottom: 30px;
}
.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}
.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #026134;
}
.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}
.blog .blog-author .social-links a {
    color: rgba(1, 41, 112, 0.5);
    margin-right: 5px;
}
.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}
.blog .blog-comments {
    margin-bottom: 30px;
}
.blog .blog-comments .comments-count {
    font-weight: bold;
}
.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}
.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}
.blog .blog-comments .comment .comment-img img {
    width: 60px;
}
.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}
.blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
}
.blog .blog-comments .comment h5 a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #026134;
}
.blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
}
.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #013ca3;
    margin-bottom: 5px;
}
.blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
}
.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}
.blog .blog-comments .reply-form p {
    font-size: 14px;
}
.blog .blog-comments .reply-form input,
.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}
.blog .blog-comments .reply-form input:focus,
.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #a0aaf8;
}
.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}
.blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #026134;
}
.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #016938;
}
.blog .blog-pagination {
    color: #024ed5;
}
.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}
.blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
}
.blog .blog-pagination li a {
    color: #026134;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
    color: #fff;
}
.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #026134;
    position: relative;
}
.blog .sidebar .sidebar-item {
    margin-bottom: 30px;
}
.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}
.blog .sidebar .search-form form input[type='text'] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
}
.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}
.blog .sidebar .search-form form button i {
    line-height: 0;
}
.blog .sidebar .search-form form button:hover {
    background: #5465f2;
}
.blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
}
.blog .sidebar .categories ul li + li {
    padding-top: 10px;
}
.blog .sidebar .categories ul a {
    color: #026134;
    transition: 0.3s;
}
.blog .sidebar .categories ul a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
}
.blog .sidebar .recent-posts .post-item + .post-item {
    margin-top: 15px;
}
.blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
}
.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}
.blog .sidebar .recent-posts h4 a {
    color: #026134;
    transition: 0.3s;
}
.blog .sidebar .recent-posts h4 a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
}
.blog .sidebar .tags {
    margin-bottom: -10px;
}
.blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
}
.blog .sidebar .tags ul li {
    display: inline-block;
}
.blog .sidebar .tags ul a {
    color: #0257ee;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #d7e6ff;
    display: inline-block;
    transition: 0.3s;
}
.blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #a5c5fe;
    font-size: 14px;
}
/*--------------------------------------------------------------
 # Footer
 --------------------------------------------------------------*/
.footer {
    background: #9cffd312;
    padding: 0 0 30px 0;
    font-size: 14px;
}
.footer .footer-newsletter {
    padding: 50px 0;
    background: #9cffd312;
    border-top: 1px solid #e1ecff;
}
.footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    color: #026134;
}
.footer .footer-newsletter form {
    margin-top: 20px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #e1ecff;
}
.footer .footer-newsletter form input[type='email'] {
    border: 0;
    padding: 8px;
    width: calc(100% - 140px);
}
.footer .footer-newsletter form input[type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 30px;
    margin: 3px;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    color: #fff;
    transition: 0.3s;
    border-radius: 4px;
}
.footer .footer-newsletter form input[type='submit']:hover {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.footer .footer-top {
    background: white url(../img/footer-bg.png) no-repeat right top;
    background-size: contain;
    border-top: 1px solid #e1ecff;
    border-bottom: 1px solid #e1ecff;
    padding: 60px 0 30px 0;
}
.footer .footer-top .footer-info {
    margin-bottom: 30px;
}
.footer .footer-top .footer-info .logo {
    line-height: 0;
    margin-bottom: 15px;
}
.footer .footer-top .footer-info .logo img {
    max-height: 60px;
    margin-right: 6px;
}
.footer .footer-top .footer-info .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #026134;
    font-family: 'Nunito', sans-serif;
    margin-top: 3px;
}
.footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Nunito', sans-serif;
}
.footer .footer-top .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
}
.footer .footer-top .social-links a:hover {
    color: #026134;
}
.footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #026134;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
}
.footer .footer-top .footer-links {
    margin-bottom: 30px;
}
.footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #d0d4fc;
    font-size: 12px;
    line-height: 0;
}
.footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}
.footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}
.footer .footer-top .footer-links ul a {
    color: #016938;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}
.footer .footer-top .footer-links ul a:hover {
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}
.footer .footer-top .footer-contact p {
    line-height: 26px;
}
.footer .copyright {
    text-align: center;
    padding-top: 30px;
    color: #026134;
}
.footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #026134;
}
@media (max-width: 992px) {
    .footer .footer-top {
        background-position: center bottom;
    }
}
.login-icon {
    font-size: 18px !important;
    padding-right: 11px;
}

/* spinner */
.spinner {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 8px solid rgba(255, 255, 255, 0.1);
    border-top-color: #20bf74;
    animation: spin 1s linear infinite;
    display: none;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.static-content {
    font-size: 25px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
}

.small-content {
    font-size: 13px;
    font-weight: 500;
}
.front-select {
    height: calc(2.25rem + 6px) !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.btn-green-gradient {
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    border-radius: 4px !important;
    color: #fff !important;
    border: none !important;
}

.btn-green-gradient:hover {
    color: #d4d3d3 !important;
}
.text-gray {
    color: #413c3c;
}

.ml-8 {
    margin-left: 4rem;
}

.ml-10 {
    margin-left: 10rem;
}
.bg-green {
    background-color: #016938;
}

.about-margin-4 {
    margin-left: 1.5rem !important;
}
.about-margin-5 {
    margin-left: 3rem !important;
}
@media (max-width: 400px) {
    .about-margin-4 {
        margin-left: 0px !important;
    }
    .about-margin-5 {
        margin-left: 0px !important;
    }
}
.active-box {
    color: #fff;
    background: linear-gradient(90deg, #016938 35%, #1fb06f 100%);
    padding: 30px;
    border-radius: 10px;
}
.inactive-box {
    color: #fff;
    background: #8e8e8e;
    padding: 30px;
    border-radius: 10px;
}
.inner-box {
    background-color: #fff;
    color: black;
    border-radius: 8px;
}
.inner-box p {
    font-size: 35px;
}
.wizard-box {
    box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -webkit-box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -moz-box-shadow: 6px 10px 49px 12px rgba(0, 0, 0, 0.53);
    border-radius: 30px;
    background-color: #fff;
    padding: 30px;
}

@media (max-width: 1024px) {
    .disclaimer-box .content {
        height: 190px;
        overflow-y: scroll;
    }
    .disclaimer-box {
        right: 15px;
        top: 130px;
    }
}

.login-bg {
    width: 100%;
    height: 100vh;
    background: url(../../../front_assets/assets/img/login-bg.png);
    background-repeat: round;
}

.login-box {
    box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -webkit-box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
    -moz-box-shadow: 6px 10px 49px 12px rgba(0, 0, 0, 0.53);
    margin-top: 28%;
    margin-left: 5%;
}

.login-img-margin {
    margin-left: 13% !important;
    margin-top: 8% !important;
}

@media (max-width: 768px) {
    .login-bg {
        width: 100%;
        height: 80vh;
        background-repeat: no-repeat;
    }
    .login-img-margin {
        margin-left: 0% !important;
        margin-top: 10% !important;
    }
    .login-box {
        box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
        -webkit-box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
        -moz-box-shadow: 6px 10px 49px 12px rgba(0, 0, 0, 0.53);
        margin-top: 5%;
        margin-left: 0%;
    }
}
@media (max-width: 576px) {
    .login-bg {
        width: 100%;
        height: 75vh;
        background-repeat: no-repeat;
    }
    .login-img-margin {
        margin-left: 0% !important;
        margin-top: 10% !important;
    }
    .login-box {
        box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
        -webkit-box-shadow: 6px 10px 49px 12px rgb(0 0 0 / 53%);
        -moz-box-shadow: 6px 10px 49px 12px rgba(0, 0, 0, 0.53);
        margin-top: 5%;
        margin-left: 0%;
    }
}
/* .input-group-text */
.input-group-text {
    padding: 0.675rem 0.75rem !important;
}

@media (min-width: 200px) and (max-width: 399px) {
    .caption {
        position: absolute;
        top: 55%;
        left: 38%;
        transform: translate(-50%, -50%);
        text-align: left;
        color: white;
        font-weight: bold;
    }
    .header-text {
        font-weight: bold;
        font-size: 17px;
        text-shadow: 1px 1px #000000;
    }
    .header-text-mini {
        font-size: 10px;
        text-shadow: 1px 1px #000000;
    }
    .header {
        transition: all 0.5s;
        z-index: 997;
        padding: 5px 0;
    }
}

@media (min-width: 400px) and (max-width: 576px) {
    .caption {
        position: absolute;
        top: 55%;
        left: 38%;
        transform: translate(-50%, -50%);
        text-align: left;
        color: white;
        font-weight: bold;
    }
    .header-text {
        font-weight: bold;
        font-size: 17px;
        text-shadow: 1px 1px #000000;
    }
    .header-text-mini {
        font-size: 10px;
        text-shadow: 1px 1px #000000;
    }
    .header {
        transition: all 0.5s;
        z-index: 997;
        padding: 5px 0;
    }
}

@media (min-width: 580px) and (max-width: 768px) {
    .caption {
        position: absolute;
        top: 45%;
        left: 64%;
        transform: translate(-50%, -50%);
        text-align: left;
        color: white;
        font-weight: bold;
    }
    .header-text {
        font-weight: bold;
        font-size: 20px;
        text-shadow: 1px 1px #000000;
    }
    .header-text-mini {
        font-size: 12px;
        text-shadow: 1px 1px #000000;
    }
    .header {
        transition: all 0.5s;
        z-index: 997;
        padding: 5px 0;
    }
}

@media (min-width: 780px) and (max-width: 990px) {
    .caption {
        position: absolute;
        top: 45%;
        left: 64%;
        transform: translate(-50%, -50%);
        text-align: left;
        color: white;
        font-weight: bold;
    }
    .header-text {
        font-weight: bold;
        font-size: 35px;
        text-shadow: 1px 1px #000000;
    }
    .header-text-mini {
        font-size: 17px;
        text-shadow: 1px 1px #000000;
    }
    .header {
        transition: all 0.5s;
        z-index: 997;
        padding: 5px 0;
    }
}

@media (min-width: 991px) {
    .caption {
        position: absolute;
        top: 35%;
        left: 30%;
        transform: translate(-50%, -50%);
        text-align: left;
        color: white;
        font-weight: bold;
    }
    .header-text {
        font-weight: bold;
        font-size: 56px;
        text-shadow: 2px 2px #000000;
    }
    .header-text-mini {
        font-size: 30px;
        text-shadow: 2px 2px #000000;
    }
}

.line {
    width: 50%;
    height: 12px;
    border-bottom: 1px solid #2bde8a;
}
